import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Masthead from "../components/masthead"
import BtnTransparent from "../components/buttons/transparent"

const NotFoundPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className="masthead hidden lg:block">
        <GatsbyImage
          image={getImage(
            data.allMdx.edges[0].node.frontmatter.masthead_desktop
          )}
          alt="Coming Soon"
        />
      </div>
      <div className="masthead block lg:hidden">
        <GatsbyImage
          image={getImage(
            data.allMdx.edges[0].node.frontmatter.masthead_mobile
          )}
          alt="Coming Soon"
        />
      </div>
      <div className="container my-8">
        <h1 className="text-xl md:text-5xl font-light mb-5">
          SORRY, PAGE NOT FOUND
        </h1>
        <p></p>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query {
    allMdx(filter: { frontmatter: { page_id: { eq: "index" } } }) {
      edges {
        node {
          frontmatter {
            slug
            page_id
            date
            title
            masthead_desktop {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 300 }
                  placeholder: BLURRED
                  breakpoints: [1080, 1366, 1920]
                  outputPixelDensities: [1, 1.5, 2]
                )
              }
            }
            masthead_mobile {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 150 }
                  placeholder: BLURRED
                  breakpoints: [650]
                  outputPixelDensities: [2]
                )
              }
            }
            highlight_left {
              childImageSharp {
                gatsbyImageData(
                  blurredOptions: { width: 100 }
                  placeholder: BLURRED
                )
              }
            }
            highlight_left_title
            highlight_right {
              childImageSharp {
                gatsbyImageData(
                  blurredOptions: { width: 100 }
                  placeholder: BLURRED
                )
              }
            }
            highlight_right_title
          }
        }
      }
    }
  }
`
export default NotFoundPage

export const Head = () => (
  <>
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=AW-782099393"
    ></script>
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=G-8FJZCNFV3R"
    ></script>
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=GTM-M7DKTD9"
    ></script>
  </>
)
