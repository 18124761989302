import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"

function Masthead(props) {
  return (
    <>
      <div className="masthead hidden lg:block">
        <GatsbyImage
          image={getImage(JSON.parse(props.desktop))}
          alt={props.imgAlt}
          placeholder="blurred"
        />
      </div>
      <div className="masthead block lg:hidden">
        <GatsbyImage
          image={getImage(JSON.parse(props.mobile))}
          alt={props.imgAlt}
          placeholder="blurred"
        />
      </div>
    </>
  )
}

Masthead.propTypes = {}

export default Masthead
