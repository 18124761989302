import * as React from "react"

const BtnTransparent = props => (
  <button
    className="flex items-center justify-center px-5 min-w-[180px] w-full max-w-[280px] md:max-w-[285px] min-h-[3.25rem] cursor-pointer mb-2.5 transition-all border-2 border-white hover:border-[#8e8e8e] text-white hover:text-[#8e8e8e]"
    {...props}
  >
    <span className="font-bold">{props.cta}</span>
  </button>
)

export default BtnTransparent
